import React, { useState, useEffect } from 'react';
import { X, User, ChevronDown, Mail, Phone, Search } from 'lucide-react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import configData from '../configData.json';
import { getCountries, getCountryCallingCode } from 'libphonenumber-js';
import CryptoJS from 'crypto-js';





const OtpAuthModal = ({ isOpen, onClose, message }) => {
    const navigate = useNavigate();
    const [countryCode, setCountryCode] = useState('IN');
    const [loginMethod, setLoginMethod] = useState('phone');
    const [searchQuery, setSearchQuery] = useState('');

    const [countries, setCountries] = useState([]);
    const [filteredCountries, setFilteredCountries] = useState([]);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [showOtpInput, setShowOtpInput] = useState(false);
    const [otp, setOtp] = useState('');
    const [alert, setAlert] = useState(false);
    const [alertContent, setAlertContent] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [expireTime, setExpireTime] = useState();

    const [startTime, setStartTime] = useState(null);

    const [intervalId, setIntervalId] = useState(null);

    const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

    useEffect(() => {
        const countryData = getCountries().map(country => ({
            code: country,
            dialCode: `+${getCountryCallingCode(country)}`,
            name: new Intl.DisplayNames(['en'], { type: 'region' }).of(country)
        }));

        countryData.sort((a, b) => a.name.localeCompare(b.name));
        setCountries(countryData);
        setFilteredCountries(countryData);
    }, []);

    useEffect(() => {
        const filtered = countries.filter(country =>
            country.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            country.dialCode.includes(searchQuery) ||
            country.code.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredCountries(filtered);
    }, [searchQuery, countries]);

    const getFullPhoneNumber = () => {
        const selectedCountry = countries.find(c => c.code === countryCode);
        const dialCode = selectedCountry ? selectedCountry.dialCode : '+91';
        return `${dialCode}${phoneNumber}`;
    };

    const handlePhoneNumberChange = (e) => {
        const value = e.target.value.replace(/\D/g, '');
        setPhoneNumber(value);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleCountrySelect = (country) => {
        setCountryCode(country.code);
        setIsDropdownOpen(false);
        setSearchQuery('');
    };

    const resetForm = () => {
        setPhoneNumber('');
        setEmail('');
        setOtp('');
        setShowOtpInput(false);
        setAlert(false);
        setAlertContent('');
        setSearchQuery('');
    };

    const validate = () => {
        if (loginMethod === 'phone' && !phoneNumber) {
            setAlert(true);
            setAlertContent('Please enter your phone number');
            return false;
        }
        if (loginMethod === 'email' && !email) {
            setAlert(true);
            setAlertContent('Please enter your email address');
            return false;
        }
        if (loginMethod === 'email' && !/\S+@\S+\.\S+/.test(email)) {
            setAlert(true);
            setAlertContent('Please enter a valid email address');
            return false;
        }
        return true;
    };
    const handleSendOtp = async (event) => {
        event.preventDefault();

        if (!validate()) {
            return false;
        }

        try {
            const verify_url = loginMethod === 'phone'
                ? configData.verifyTeacherByPhone
                : configData.verifyTeacherByPhone;

            const payload = loginMethod === 'phone'
                ? { phone_number: getFullPhoneNumber() }
                : { phone_number: email };

            const result = await axios.post(verify_url, payload);

            if (result.status === 200) {
                setShowOtpInput(true);
                setAlert(false);
            } else if (result.status === 202) {
                if (result.data.detail && result.data.detail.includes("is not registered")) {
                    setAlertContent("It looks like you don’t have an account yet. Sign up to get started!");
                } else {
                    setAlertContent(result.data.detail);
                }
                setAlert(true);
            } else {
                throw new Error('No response from server');
            }
        } catch (error) {
            console.log('Exception -> ', error);
            setAlert(true);
            setAlertContent('It looks like you don’t have an account yet. Sign up to get started!');
            return false;
        }
    };
    const encryptData = (userId, emailAddress) => {
        const encryptedUserId = CryptoJS.AES.encrypt(userId, SECRET_KEY).toString();
        const encryptedEmailAddress = CryptoJS.AES.encrypt(emailAddress, SECRET_KEY).toString();

        return {
            encryptedUserId,
            encryptedEmailAddress
        };
    };



    const handleVerifyOtp = async (event) => {
        event.preventDefault();

        try {
            const verify_otp_url = configData.verifyOtpWithAzure;

            const payload = loginMethod === 'phone'
                ? { phone_number: getFullPhoneNumber(), otp }
                : { phone_number: email, otp };

            const result = await axios.post(verify_otp_url, payload);

            if (result.status === 200) {
                const data = result.data;

                const accessToken = String(data.access_token);
                const userId = String(data.user_id);
                const emailAddress = String(data.email_address);
                const refresh_token = String(data?.refresh_token);

                try {
                    const encryptedUserId = CryptoJS.AES.encrypt(String(userId), SECRET_KEY).toString();
                    const encryptedEmailAddress = CryptoJS.AES.encrypt(String(emailAddress), SECRET_KEY).toString();
                    const encryptedRefreshToken = CryptoJS.AES.encrypt(String(refresh_token), SECRET_KEY).toString();

                    const expireTokenTime = result.data.expires_in;
                    setExpireTime(expireTokenTime);

                    localStorage.setItem('expire_token', expireTokenTime);
                    localStorage.setItem('access_token', accessToken);
                    localStorage.setItem('user_id', encryptedUserId);
                    localStorage.setItem('email_address', encryptedEmailAddress);
                    localStorage.setItem('refresh_token', encryptedRefreshToken)

                    resetForm();
                    onClose();
                    navigate('/agents/Hevolve');

                    const startLoginTime = Date.now();
                    setStartTime(startLoginTime);

                    const interval = setInterval(() => {
                        const remainingTime = expireTokenTime * 1000 - (Date.now() - startLoginTime);

                        if (remainingTime <= 5000) {
                            renewToken(userId);
                            clearInterval(interval);
                        }
                    }, 1000);

                    setIntervalId(interval);

                } catch (encryptionError) {
                    throw new Error('Failed to encrypt user data');
                }
            } else {
                setAlertContent('Invalid OTP');
                setAlert(true);
                throw new Error('Wrong OTP');
            }
        } catch (error) {
            setAlertContent('Invalid OTP. Please try again.');
            setAlert(true);
        }
    };


    const renewToken = async (userId) => {
        try {
            const renewTokenUrl = configData.renew_token;
            const response = await axios.post(renewTokenUrl, { user_id: userId });

            if (response.status === 200) {


                const newAccessToken = String(response.data.access_token);
                localStorage.setItem('expire_token', newAccessToken);
            } else {
                console.error('Failed to renew token');
            }
        } catch (renewError) {
            console.error('Error renewing token', renewError);
        }
    };


    useEffect(() => {
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [intervalId]);


    if (!isOpen) return null;




    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-40">
            <div className="bg-white rounded-lg p-6 w-96 relative">
                <p>{message || "Please log in again."}</p>
                <button
                    onClick={onClose}
                    className="absolute right-4 top-4 text-gray-500 hover:text-gray-700"
                >
                    <X size={20} />
                </button>

                <div className="text-center mb-6">
                    <div className="w-12 h-12 bg-blue-100 rounded-full mx-auto mb-4 flex items-center justify-center">
                        <User className="w-6 h-6 text-blue-500" />
                    </div>
                    <h2 className="text-xl font-semibold">User Sign in</h2>
                </div>

                <div className="flex justify-center space-x-4 mb-6">
                    <button
                        onClick={() => {
                            setLoginMethod('phone');
                            resetForm();
                        }}
                        className={`flex items-center space-x-2 px-4 py-2 rounded-full ${loginMethod === 'phone'
                            ? 'bg-blue-500 text-white'
                            : 'bg-gray-200 text-gray-600'
                            }`}
                    >
                        <Phone size={16} />
                        <span>Phone</span>
                    </button>
                    <button
                        onClick={() => {
                            setLoginMethod('email');
                            resetForm();
                        }}
                        className={`flex items-center space-x-2 px-4 py-2 rounded-full ${loginMethod === 'email'
                            ? 'bg-blue-500 text-white'
                            : 'bg-gray-200 text-gray-600'
                            }`}
                    >
                        <Mail size={16} />
                        <span>Email</span>
                    </button>
                </div>

                {alert && (
                    <div className="mb-4 p-3 bg-red-100 text-red-700 rounded">
                        {alertContent}
                    </div>
                )}

                {!showOtpInput ? (
                    <div className="space-y-4">
                        {loginMethod === 'phone' ? (
                            <div className="flex gap-2">
                                <div className="relative">
                                    <button
                                        type="button"
                                        className="flex items-center justify-between w-20 px-3 py-2 text-gray-700 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                                    >
                                        {countries.find(c => c.code === countryCode)?.dialCode ?? '+91'}

                                        <ChevronDown size={16} />
                                    </button>

                                    {isDropdownOpen && (
                                        <div className="absolute z-10 w-64 mt-1 bg-white border rounded-md shadow-lg">
                                            <div className="p-2 border-b">
                                                <div className="relative">
                                                    <Search className="absolute left-2 top-2.5 h-4 w-4 text-gray-400" />
                                                    <input
                                                        type="text"
                                                        placeholder="Search country..."
                                                        value={searchQuery}
                                                        onChange={handleSearchChange}
                                                        className="w-full pl-8 pr-4 py-2 border rounded text-sm text-black focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                    />
                                                </div>
                                            </div>
                                            <div className="max-h-60 overflow-y-auto">
                                                {filteredCountries.map((country) => (
                                                    <button
                                                        key={country.code}
                                                        className="block w-full px-4 py-2 text-left text-sm text-black-700 hover:bg-gray-100"
                                                        onClick={() => handleCountrySelect(country)}
                                                    >
                                                        <span className="mr-2">{country.name}</span>
                                                        <span className="text-gray-500">{country.dialCode}</span>
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <input
                                    type="tel"
                                    id="phoneNumber"
                                    maxLength={14}
                                    value={phoneNumber}
                                    onChange={handlePhoneNumberChange}
                                    placeholder="Enter Phone Number"
                                    className="flex-1 px-4 py-2 border text-gray-700 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            </div>
                        ) : (
                            <input
                                type="email"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Enter Email Address"
                                className="w-full px-4 py-2 border text-gray-700 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                        )}
                        <button
                            onClick={handleSendOtp}
                            className="w-full py-2 px-4 rounded text-white font-semibold"
                            style={{
                                background: 'linear-gradient(to right, #00e89d, #0078ff)',
                            }}
                        >
                            GET OTP
                        </button>
                    </div>
                ) : (
                    <div className="space-y-4">
                        <div>
                            <input
                                type="text"
                                id="otp"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                                placeholder="Enter OTP"
                                className="w-full px-4 py-2 text-gray-700 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                                maxLength={6}
                            />
                        </div>
                        <button
                            onClick={handleVerifyOtp}
                            className="w-full py-2 px-4 rounded text-white font-semibold"
                            style={{
                                background: 'linear-gradient(to right, #00e89d, #0078ff)',
                            }}
                        >
                            Verify OTP
                        </button>
                    </div>
                )}

                <div className="mt-4 text-center text-sm text-gray-600">
                    Don't have an account?
                    <button
                        className="text-blue-500 hover:text-blue-700 ml-1"
                        onClick={() => {
                            onClose();
                            const element = document.getElementById("signup-section");
                            element?.scrollIntoView({ behavior: "smooth" });
                        }}
                    >
                        Sign Up
                    </button>
                </div>
            </div>
        </div>
    );
};

export default OtpAuthModal;