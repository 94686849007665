import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from "../footer";
import Navbar from "../navbar";
import './agents.css';
import { X } from 'lucide-react';

import AgentPoster from '../../assets/images/AgentPoster.png';

const Agents = ({
    isOverlay = false,
    onClose = () => { },
    onAgentSelect = () => { },
    predefinedAgents = null
}) => {
    const [agentsData, setAgentsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredAgents, setFilteredAgents] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchAgents = async () => {
            try {
                if (predefinedAgents && predefinedAgents.length > 0) {
                    const validAgents = predefinedAgents.filter(agent => agent.name && agent.name.trim() !== '');
                    setAgentsData(validAgents);
                    setFilteredAgents(validAgents);
                    setLoading(false);
                    return;
                }

                // Otherwise fetch from API
                const response = await fetch('https://mailer.hertzai.com/getprompt_all/');
                const data = await response.json();

                if (response.ok) {
                    // Filter out agents without a name
                    const validAgents = data.filter(agent => agent.name && agent.name.trim() !== '');
                    setAgentsData(validAgents);
                    setFilteredAgents(validAgents);
                } else {
                    throw new Error('Failed to fetch agents');
                }
            } catch (error) {
                console.error('Error fetching agents:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchAgents();
    }, [predefinedAgents]);

    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);

        const filtered = agentsData.filter(agent =>
            agent.name.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredAgents(filtered);
    };

    if (loading) {
        return <div className={isOverlay ? "text-white text-center p-8" : ""}>Loading...</div>;
    }

    if (isOverlay) {
        return (
            <div className="fixed inset-0 w-full bg-black bg-opacity-80 z-50 flex justify-center items-center overflow-y-auto">
                <div className="bg-gray-900  rounded-lg shadow-lg max-h-[95vh] flex flex-col">
                    <div className="flex justify-between items-center p-4 border-b border-gray-700">
                        <h2 className="text-xl font-bold text-white">All Agents</h2>
                        <button
                            onClick={onClose}
                            className="text-gray-400 hover:text-white rounded-full p-1 transition-colors"
                        >
                            <X className="w-6 h-6" />
                        </button>
                    </div>

                    {/* Search Bar */}
                    <div className="flex justify-center m-4">
                        <input
                            type="text"
                            placeholder="Search agents..."
                            value={searchQuery}
                            onChange={handleSearchChange}
                            className="p-2 rounded-md border border-gray-300 w-1/2 text-black"
                        />
                    </div>

                    {/* Agents Grid */}
                    <div className="flex-1 overflow-y-auto p-4">
                        <div className="agents-grid">
                            {filteredAgents.length === 0 ? (
                                <div className="no-agents-message text-white">
                                    No agents match your search. Please try a different query.
                                </div>
                            ) : (
                                filteredAgents.map((agent, index) => (
                                    <AgentCard
                                        key={index}
                                        agent={agent}
                                        isOverlay={true}
                                        onSelect={() => onAgentSelect(agent)}
                                    />
                                ))
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    // Regular standalone view
    return (
        <>
            <div className='bg-[#212A31]'>
                <Navbar />

                <div className="min-h-screen bg-[#212A31] pt-28">
                    {/* Search Bar */}
                    <div className="flex justify-center mb-8">
                        <input
                            type="text"
                            placeholder="Search agents..."
                            value={searchQuery}
                            onChange={handleSearchChange}
                            className="p-2 rounded-md border border-gray-300 w-1/2 text-black"
                        />
                    </div>

                    <div className="agents-grid">
                        {filteredAgents.length === 0 ? (
                            <div className="no-agents-message">
                                No agents match your search. Please try a different query.
                            </div>
                        ) : (
                            filteredAgents.map((agent, index) => (
                                <AgentCard
                                    key={index}
                                    agent={agent}
                                    isOverlay={false}
                                />
                            ))
                        )}
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

const AgentCard = ({ agent, isOverlay = false, onSelect = () => { } }) => {
    const navigate = useNavigate();

    useEffect(() => {
        const link = document.createElement('link');
        link.href = 'https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css';
        link.rel = 'stylesheet';
        document.head.appendChild(link);

        return () => {
            document.head.removeChild(link);
        };
    }, []);

    const handleButtonClick = () => {
        if (isOverlay) {
            onSelect(agent);
            return;
        }

        const agentName = agent.name.replace(/\s+/g, '-');
        navigate(`/agents/${agentName}`, {
            state: {
                agentData: agent,
            }
        });
    };

    return (
        <div
            onClick={handleButtonClick}
            style={{ backgroundColor: '#212A31', border: '1px solid grey' }}
            className="relative flex flex-col overflow-hidden bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800 w-64"
        >
            <div
                style={{ backgroundColor: '#212A31' }}
                className="pb-0 relative overflow-hidden after:content-[''] after:absolute after:inset-0 after:mx-auto after:w-72 after:h-72 after:rounded-full"
            >
                <img
                    src={
                        agent.teacher_image_url
                            ? agent.teacher_image_url
                            : agent.image_url
                                ? agent.image_url
                                : AgentPoster
                    }
                    className="relative rounded-t-md shadow-md dark:shadow-slate-700 z-1 object-cover w-full"
                    alt={agent.name}
                />
            </div>
            <div className="px-6 py-4 flex flex-col justify-center items-center relative">
                <h3 className="font-bold text-xl mb-2 text-center text-white">{agent.name}</h3>

                <p className="text-slate-400 mb-2 text-center line-clamp-3">
                    {agent.video_text || "Agent description goes here."}
                </p>
            </div>
            <button
                onClick={(e) => {
                    e.stopPropagation();
                    handleButtonClick();
                }}
                className="bg-blue-500 text-center w-40 flex justify-center items-center hover:bg-blue-700 absolute bottom-1 left-1/2 -translate-x-1/2 text-white font-bold py-2 px-4 rounded transition-transform duration-300 hover:scale-110"
            >
                Talk To Agent
            </button>
        </div>
    );
};

export default Agents;